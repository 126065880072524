<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header" title="延迟利息" fixed>
      <fb-button slot="left" icon="back" @click="backToNative()" size="small" type="primary"> 返回 </fb-button>
      <div slot="right">
        <tool-head type="defeeredInterest" @clickItem="clickItem">
          <font-size-comp @setFontSize="setFontSize" ref="fontSizeComp" />
        </tool-head>
      </div>
    </fb-header>
    <div id="tools" class="tools-deferredInterest" style="font-size: 14px !important">
      <div class="box" style="padding: 0px 20px">
        <div class="box-left">
          金额<span v-show="money" style="margin-left: 5px">{{ yuanToWan(money) }}</span>
        </div>
        <div class="box-right" style="display: flex; align-items: center">
          <van-field
            v-model="money"
            input-align="right"
            placeholder="请输入金额"
            type="number"
            readonly
            clickable
            @touchstart.stop="
              show = true
              keyboard = 'money'
            "
            style="flex: 1"
            :class="keyboard === 'money' && show ? 'van-number-keyboard-input-select' : ''"
          />
          <van-icon name="close" @click="money = ''" />
        </div>
      </div>
      <div class="box" style="padding: 0px 20px">
        <div class="box-left">日期</div>
        <div class="box-right" style="flex: 1">
          <div class="box-right-time" @click="startTimeShow = true">
            <span v-show="startTime">{{ startTime }}</span>
            <span style="color: #ccc" v-show="!startTime">选择开始时间</span>
          </div>
          <div>至</div>
          <div class="box-right-time" @click="endTimeShow = true">
            <span v-show="endTime">{{ endTime }}</span>
            <span style="color: #ccc" v-show="!endTime">选择结束时间</span>
          </div>
          <div style="width: 80px; text-align: right">
            <span v-show="day > 0">{{ day }}天</span>
          </div>
        </div>
      </div>
      <div class="box" style="padding: 0px 20px">
        <div style="display: flex; align-items: center; width: 45%">
          <div class="box-left">判决利率</div>
          <div class="box-right">
            <div class="input-box">
              <van-field
                v-model="judgmentInterest"
                input-align="right"
                placeholder="请输入"
                type="number"
                readonly
                clickable
                @touchstart.stop="
                  show = true
                  keyboard = 'judgmentInterest'
                "
                :class="keyboard === 'judgmentInterest' && show ? 'van-number-keyboard-input-select' : ''"
              />
              <van-icon name="close" @click="judgmentInterest = ''" />
            </div>
            <span>‱</span>
          </div>
        </div>
        <div style="display: flex; align-items: center; width: 45%">
          <div class="box-left">延迟利率</div>
          <div class="box-right">
            <div class="input-box">
              <van-field
                v-model="deferredInterest"
                input-align="right"
                placeholder="请输入"
                type="number"
                readonly
                clickable
                @touchstart.stop="
                  show = true
                  keyboard = 'deferredInterest'
                "
                :class="keyboard === 'deferredInterest' && show ? 'van-number-keyboard-input-select' : ''"
              />
              <van-icon name="close" @click="deferredInterest = ''" />
            </div>
            <span>‱</span>
          </div>
        </div>
      </div>
      <!-- <div class="btn">
        <van-button plain type="info" @click="reset">重置</van-button>
        <van-button @click="getResult" style="margin-left:20px" type="info"
          >计算</van-button
        >
      </div> -->
      <div class="interest-result" v-show="result">
        <div>利息：{{ interest }}</div>
        <div>本金利息：{{ result }}</div>
        <i @click="copy" style="color: #000000; margin-left: 20px; cursor: pointer" class="iconfont icon-fuzhi"></i>
      </div>
      <div class="interest-result">
        <span @click="isShowInfo = !isShowInfo" v-show="result">详情</span>
        <span v-show="!result"></span>
        <span>
          <span @click="copyAll" style="margin-right: 10px" v-show="result">复制</span>
          <span @click="getWORD" style="margin-right: 10px" v-show="result && !iphone">导出word</span>
          <!-- <span @click="getEXCEL" style="margin-right:10px" v-show="result"
            >导出excel</span
          > -->
          <span @click="share" style="margin-right: 10px">分享</span>
          <span @click="feedback">反馈</span>
        </span>
      </div>
      <div class="interest-line"></div>
      <div v-show="isShowInfo" style="padding: 0 20px">
        <p style="color: #ccc">
          利息=本金×判决利率*天数+本金*延迟利率*延迟天数<span style="margin-left: 10px">{{ resultObj.day }}天</span>
        </p>
        <p>{{ text1 }}</p>
        <p style="color: #ccc">本息=本金+利息</p>
        <p>{{ text2 }}</p>
      </div>
    </div>
    <van-popup v-model="startTimeShow" position="top">
      <van-datetime-picker v-model="startTimeDate" type="date" title="选择开始时间" :min-date="minDate" :max-date="maxDate" @confirm="startTimeConfirm" @cancel="startTimeShow = false" />
    </van-popup>
    <van-popup v-model="endTimeShow" position="top">
      <van-datetime-picker v-model="endTimeDate" type="date" title="选择结束时间" :min-date="minDate" :max-date="maxDate" @confirm="endTimeConfirm" @cancel="endTimeShow = false" />
    </van-popup>
    <van-number-keyboard :show="show" theme="custom" extra-key="." close-button-text="完成" @blur="show = false" @input="onInput" @delete="onDelete" />
  </fb-page>
</template>
<script>
import fontSizeComp from '../../components/font-size-comp.vue'
import toolHead from '../../components/toolHead.vue'
import goBackMixin from '@mixins/goBack'
import { qiniuBase } from '~api-config'
import longUrl2ShortUrl from '@/common/longUrl2ShortUrlUtils.js'

export default {
  name: 'deferredInterest',
  mixins: [goBackMixin],
  components: {
    fontSizeComp,
    toolHead,
  },
  data() {
    return {
      fontSize: 14,
      show: false,
      keyboard: '',
      startTimeShow: false,
      endTimeShow: false,
      startTimeDate: new Date(),
      endTimeDate: new Date(),
      maxDate: new Date(2100, 12, 31),
      minDate: new Date(2014, 7, 1),
      day: 0,
      money: '', // 金额
      startTime: '', // 开始时间
      endTime: '', // 结束时间
      judgmentInterest: 5, // 判决利率
      deferredInterest: 1.75, // 延迟利率
      interest: '', // 计算后的利息
      result: '', //  计算后的本息
      isShowInfo: false, // 是否显示详情
      text1: '', // 利息计算过程
      text2: '', // 本息计算过程
      resultObj: '', // 导出的数据存放
      iphone: false,
    }
  },
  computed: {},
  methods: {
    clickItem(item) {
      this.addHistory()
      const { condition, type } = item
      const conditionObj = JSON.parse(condition)
      if (type === 'defeeredInterest') {
        const { startTime, endTime, money, judgmentInterest, deferredInterest } = conditionObj
        this.startTime = startTime
        this.endTime = endTime
        this.judgmentInterest = judgmentInterest
        this.deferredInterest = deferredInterest
        this.money = money
        this.getResult()
      }
    },
    addHistory() {
      const type = 'defeeredInterest'
      const { startTime, endTime, money, judgmentInterest, deferredInterest } = this
      if (!startTime || !endTime || !money || !judgmentInterest || !deferredInterest) {
        return
      }
      const conditionObj = {
        startTime,
        endTime,
        money,
        judgmentInterest,
        deferredInterest,
      }
      let title = ' ' + money + '元 '
      title += startTime + ' 至 ' + endTime + ' '
      title += ' 延迟利息'
      if (this.result) {
        this.$axios
          .post(`${this.$base}/management/utils/history/add`, {
            type,
            condition: JSON.stringify(conditionObj),
            title,
          })
          .then((res) => {
            if (res.data.code === 200) {
              // this.$bus.$emit('add-history-suc', 'defeeredInterest')
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    // 设置字体大小
    setFontSize() {},
    // 数字键盘组件输入事件
    onInput(value) {
      console.log(value)
      this[this.keyboard] += value + ''
    },
    // 数字键盘组件删除事件
    onDelete(value) {
      var money = this[this.keyboard].toString()
      this[this.keyboard] = money.substring(0, money.length - 1)
    },
    // 将元转换成万元
    yuanToWan(money) {
      money = parseFloat(money)
      let result = ''
      const wan = parseInt(money / 10000)
      if (wan > 0) {
        result = wan + '万'
      }
      let yuan = money - wan * 10000
      yuan = parseFloat(yuan.toFixed(2))
      if (yuan !== 0) {
        result = result + yuan + '元'
      }
      return result
    },
    // 时间戳转换成时间
    timestampToTime(date) {
      var Y = date.getFullYear() + '-'
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ''
      return Y + M + D
    },
    // 开始时间选择器点击确定事件
    startTimeConfirm(date) {
      this.startTimeDate = date
      this.startTime = this.timestampToTime(date)
      this.startTimeShow = false
    },
    // 结束时间选择器点击确定事件
    endTimeConfirm(date) {
      this.endTimeDate = date
      this.endTime = this.timestampToTime(date)
      this.endTimeShow = false
    },
    // 点击重置
    reset() {
      this.startTime = ''
      this.endTime = ''
      this.money = ''
      this.judgmentInterest = '' // 判决利率
      this.deferredInterest = '' // 延迟利率
      this.interest = ''
      this.result = ''
      this.isShowInfo = false
    },

    // 点击计算按钮，获取结果
    getResult() {
      if (!this.money) {
        return
        // return this.$notify('金额不能为空')
      }
      if (!this.startTime) {
        return
        // return this.$notify('开始时间不能为空')
      }
      if (!this.endTime) {
        return
        // return this.$notify('结束时间不能为空')
      }
      if (new Date(this.startTime).getTime() >= new Date(this.endTime).getTime()) {
        return
        // return this.$notify('结束时间必须大于开始时间')
      }
      if (!this.judgmentInterest) {
        return
        // return this.$notify('判决利率不能为空')
      }
      if (!this.deferredInterest) {
        return
        // return this.$notify('延迟利率不能为空')
      }
      const dateTime = new Date(this.endTime).getTime() - new Date(this.startTime).getTime()
      const d = parseInt(dateTime / 1000 / 60 / 60 / 24) + 1
      this.money = parseFloat(this.money)
      this.judgmentInterest = parseFloat(this.judgmentInterest)
      this.deferredInterest = parseFloat(this.deferredInterest)
      this.interest = ((this.money * this.judgmentInterest) / 10000) * d + ((this.money * this.deferredInterest) / 10000) * d
      this.interest = parseFloat(this.interest.toFixed(2))
      this.result = parseFloat((this.money + this.interest).toFixed(2))
      // this.result = this.money + this.interest
      this.text1 = `利息=${this.money}*${this.judgmentInterest}‱*${d}+${this.money}*${this.deferredInterest}‱*${d}=${this.interest}`
      this.text2 = `本息=${this.money}+${this.interest}=${this.result}`
      // 存下结果，方便导出
      this.resultObj = {
        money: this.money,
        result: this.result,
        interest: this.interest,
        startTime: this.startTime,
        endTime: this.endTime,
        day: d,
        judgmentInterest: this.judgmentInterest,
        deferredInterest: this.deferredInterest,
        text1: this.text1,
        text2: this.text2,
      }
    },

    // 点击导出word
    getWORD() {
      const text = `\t律呗利息计算\t\n金额: ${this.yuanToWan(this.resultObj.money)}\n日期：${this.resultObj.startTime}——${this.resultObj.endTime}\n判决利率：${
        this.resultObj.judgmentInterest
      }‱   延迟利率：${this.resultObj.deferredInterest}‱\n利息：${this.resultObj.interest}元   本息：${this.resultObj.result}元\n\n详情\n利息=本金×判决利率*天数+本金*延迟利率*延迟天数    ${
        this.resultObj.day
      }天\n${this.resultObj.text1}\n本息=本金+利息\n${this.resultObj.text2}`
      this.getTxt(text)
    },
    // 格式化时间 年-月-日 时：分：秒
    formatDate(fmt, date) {
      let ret
      const opt = {
        'Y+': date.getFullYear().toString(), // 年
        'm+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'M+': date.getMinutes().toString(), // 分
        'S+': date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      }
      for (const k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
          fmt = fmt.replace(ret[1], ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
        }
      }
      return fmt
    },
    // 生成txt
    getTxt(text) {
      // const time = this.formatDate('YYYY年mm月dd日HH点MM分SS', new Date())
      // console.log('时间戳:', time)
      var blob = new Blob([text], { type: 'text/plain;charset=utf-8' })
      console.log(blob)
      const file = new File([blob], '计算结果.txt', {
        type: 'text/plain;charset=utf-8',
      })
      console.log(file)
      const qiniu = new this.$qiniu()
      qiniu.UploadFile(
        'DEFAULT',
        file,
        {
          next: (file) => {},
          complete: () => {},
          error: () => {
            this.$toast('上传失败，上传终止')
          },
        },
        (fileopt, key, file) => {
          // 拼接完整的图片的地址
          const url = window.location.protocol + qiniuBase + '/' + key
          const name = url.substring(url.lastIndexOf('/') + 1)
          // const pathUrl = url.substring(0, url.lastIndexOf('/')) // 图片路径
          const ua = navigator.userAgent
          if (ua === this.$ua.a) {
            // eslint-disable-next-line
            // AND2JS.showFilePreview(url)
            window.showFilePreview.postMessage(
              JSON.stringify({
                filePath: url,
                fileName: name,
              })
            )
          } else if (ua === 'fb-flutter-Webview') {
            // flutter环境
            // eslint-disable-next-line
            try {
              // 之前写的方法
              // window.fbDownloadFile.postMessage(url)
              // 新改的方法
              window.showFilePreview.postMessage(
                JSON.stringify({
                  filePath: url,
                  fileName: name,
                })
              )
            } catch (error) {
              console.log(error, 'fbDownloadFile.postMessage')
            }
          } else if (ua === this.$ua.i) {
            this.iphone = true
            // ios环境
            // eslint-disable-next-line
            // window.webkit.messageHandlers.fbDownloadFile.postMessage(url)
          } else {
            // window.location = url
            console.log('下载地址:', url)
          }
        }
      )
    },
    // 点击导出excel
    // getEXCEL() {
    //   // 生成csv
    //   this.getCSV()
    // },
    // 生成csv
    // getCSV() {
    //   const time = this.formatDate('YYYY年mm月dd日HH点MM分SS', new Date())
    //   // console.log('时间戳:', time)
    //   // 解决中文乱码问题
    //   let str = '\ufeff'
    //   str += '序号,总金额,开始时间,结束时间,天数,判决利率,延迟利率,利息总额,本息总额\n'
    //   str += '1,'
    //   str += `${this.resultObj.money},`
    //   str += `${this.resultObj.startTime},`
    //   str += `${this.resultObj.endTime},`
    //   str += `${this.resultObj.day},`
    //   str += `${this.resultObj.judgmentInterest},`
    //   str += `${this.resultObj.deferredInterest},`
    //   str += `${this.resultObj.interest},`
    //   str += `${this.resultObj.result},`
    //   var blob = new Blob([str], { type: 'text/csv;charset=utf-8' })
    //   console.log(blob)
    //   const file = new File([blob], '计算结果.csv', {
    //     type: 'text/csv;charset=utf-8',
    //   })
    //   console.log(file)
    //   const qiniu = new this.$qiniu()
    //   qiniu.UploadFile(
    //     'DEFAULT',
    //     file,
    //     {
    //       next: (file) => { },
    //       complete: () => { },
    //       error: () => {
    //         this.$toast('上传失败，上传终止')
    //       },
    //     },
    //     (fileopt, key, file) => {
    //       // 拼接完整的图片的地址
    //       const url = window.location.protocol + qiniuBase + '/' + key
    //       console.log(url, '====>>> 接口返参20', '没使用')
    //       const ua = navigator.userAgent
    //       if (ua === this.$ua.a) {
    //         // eslint-disable-next-line
    //         AND2JS.showFilePreview(url)
    //       } else if (ua === 'fb-flutter-Webview') {
    //         // flutter环境
    //         // eslint-disable-next-line
    //         try {
    //           // 之前写的方法
    //           // window.fbDownloadFile.postMessage(url)
    //           // 新改的方法
    //           window.showFilePreview.postMessage(
    //             JSON.stringify({
    //               filePath: url,
    //               fileName: time,
    //             })
    //           )
    //         } catch (error) {
    //           console.log(error, 'fbDownloadFile.postMessage')
    //         }
    //       } else if (ua === this.$ua.i) {
    //         this.iphone = true
    //         // eslint-disable-next-line
    //         // window.webkit.messageHandlers.fbDownloadFile.postMessage(url)
    //       } else {
    //         window.location = url
    //       }
    //     }
    //   )
    // },
    // 复制全部内容
    async copyAll() {
      let str = ''
      str += `利息=本金×判决利率*天数+本金*延迟利率*延迟天数      ${this.resultObj.day}天\n`
      str += `${this.text1}\n`
      str += '本息=本金+利息\n'
      str += `${this.text2}`
      let url = window.location.href
      const res = await longUrl2ShortUrl(url)
      if (res.status === 200) {
        url = res.data
        str += '\n' + url
      }
      const transfer = document.createElement('textarea')
      document.body.appendChild(transfer)
      transfer.value = str // 这里表示想要复制的内容
      transfer.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
      }
      transfer.blur()
      document.body.removeChild(transfer)
      this.$notify({
        type: 'success',
        message: '已复制，请粘贴到QQ或微信中',
      })
      this.addHistory()
    },
    // 点击复制图标
    copy() {
      const text = `利息：${this.interest}元，本金利息：${this.result}元`
      const transfer = document.createElement('input')
      document.body.appendChild(transfer)
      transfer.value = text // 这里表示想要复制的内容
      transfer.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
      }
      transfer.blur()
      document.body.removeChild(transfer)
      this.$notify({
        type: 'success',
        message: '已复制，请粘贴到QQ或微信中',
      })
      this.addHistory()
    },

    // 点击分享
    async share() {
      let text = window.location.href
      if (this.result) {
        const obj = {
          money: this.resultObj.money,
          startTime: this.resultObj.startTime,
          endTime: this.resultObj.endTime,
          judgmentInterest: this.resultObj.judgmentInterest,
          deferredInterest: this.resultObj.deferredInterest,
        }
        text += `?data=${JSON.stringify(obj)}`
      }
      const res = await longUrl2ShortUrl(text)
      if (res.status === 200) {
        text = res.data
      }
      let title = ''
      if (this.result) {
        title = `利息${this.interest}元`
      } else {
        title = '延迟利息'
      }
      const ua = navigator.userAgent
      if (ua === this.$ua.a) {
        // eslint-disable-next-line no-undef
        AND2JS.shareCaseFileWithDes(title, '律呗延迟利息计算工具', window.encodeURI(text))
      } else if (ua === this.$ua.i) {
        window.webkit.messageHandlers.shareCaseFile.postMessage(
          JSON.stringify({
            filename: title,
            url: window.encodeURI(text),
          })
        )
      } else {
        const transfer = document.createElement('input')
        document.body.appendChild(transfer)
        transfer.value = text // 这里表示想要复制的内容
        transfer.select()
        if (document.execCommand('copy')) {
          document.execCommand('copy')
        }
        transfer.blur()
        document.body.removeChild(transfer)
        this.$notify({
          type: 'success',
          message: '已复制本网页地址，请粘贴到QQ或微信中',
        })
      }
      this.addHistory()
    },
    // 点击反馈
    feedback() {
      this.$router.push({
        name: 'feedback',
      })
    },
  },
  created() {
    if (this.$route.query.data) {
      const data = JSON.parse(this.$route.query.data)
      this.money = data.money
      this.startTime = data.startTime
      this.endTime = data.endTime
      this.judgmentInterest = data.judgmentInterest
      this.deferredInterest = data.deferredInterest
      this.getResult()
    } else {
      const endY = new Date().getFullYear()
      const startY = endY - 1
      let M = new Date().getMonth() + 1
      M = M >= 10 ? M : '0' + M
      let D = new Date().getDate()
      D = D >= 10 ? D : '0' + D
      this.startTime = startY + '-' + M + '-' + D
      this.endTime = endY + '-' + M + '-' + D
      this.$watch('startTime', function () {
        const dateTime = new Date(this.endTime).getTime() - new Date(this.startTime).getTime()
        const day = parseInt(dateTime / 1000 / 60 / 60 / 24) + 1
        this.day = day
        this.getResult()
      })
      this.$watch('endTime', function () {
        const dateTime = new Date(this.endTime).getTime() - new Date(this.startTime).getTime()
        const day = parseInt(dateTime / 1000 / 60 / 60 / 24) + 1
        this.day = day
        this.getResult()
      })
      const dateTime = new Date(this.endTime).getTime() - new Date(this.startTime).getTime()
      const day = parseInt(dateTime / 1000 / 60 / 60 / 24) + 1
      this.day = day
    }
  },
  mounted() {
    const ul = document.getElementsByClassName('van-picker-column__wrapper')
    for (var i = 0; i < ul.length; i++) {
      ul[i].style.setProperty('line-height', '44px', 'important')
      const li = ul[i].getElementsByClassName('van-picker-column__item')
      li.style.setProperty('height', '44px', 'important')
    }
    this.$nextTick(() => {
      this.$refs.fontSizeComp.fontSize = 20
      this.$refs.fontSizeComp.setFontSize()
    })
  },
  watch: {
    money(value) {
      if ((value + '').indexOf('.') === value.length - 1) {
        return
      }
      this.money = (value + '').replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      this.getResult()
    },
    judgmentInterest(value) {
      this.getResult()
    },
    deferredInterest(value) {
      this.getResult()
    },
  },
}
</script>
<style lang="stylus">
.van-number-keyboard-input-select
  background rgba(25,137,250,0.3)
.tools-deferredInterest
  position relative
  & .box
    box-sizing border-box
    width 100%
    display flex
    align-items center
    justify-content space-between
    border-bottom 1px solid #CCC
    & .van-cell
      padding 10px 0px
    & .van-radio-group
      display flex
      align-items center
    & .box-left
      & .input-box
        width 60px
      & .van-switch
        font-size 16px
    & .box-right
      display flex
      flex-direction row
      align-items center
      justify-content flex-end
      flex-wrap wrap
      flex 1
      & .input-box
        flex 1
        display flex
        align-items center
      & .box-right-time
        flex 1
        text-align center
        height 46px
        line-height 46px
      & .other-item
        padding 5px 10px
        margin-top 5px
        margin-left 10px
        box-sizing border-box
        background rgba(0,0,0,0.5)
        color #FFF
  & .btn
    margin-top 20px
    display flex
    align-items center
    justify-content center
  & .interest-result-list
    padding 0 20px
    box-sizing border-box
    color red
    & > div
      padding 5px 0
      box-sizing border-box
      display flex
      flex-direction row
      align-items center
      justify-content space-between
  & .interest-result
    box-sizing border-box
    padding 0 20px
    margin-top 20px
    display flex
    flex-direction row
    align-items center
    color red
    justify-content space-between
    & > span
      cursor pointer
      color #0079FE
  & .interest-line
    width 100%
    height 1px
    background #cccccc
    margin 10px 0
  & .interest-table-title
    width 100%
    display flex
    justify-content space-between
    text-align center
    & > div
      flex 2
</style>
