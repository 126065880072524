var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: "延迟利息", fixed: "" },
          slot: "header",
        },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: {
                click: function ($event) {
                  return _vm.backToNative()
                },
              },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
          _c(
            "div",
            { attrs: { slot: "right" }, slot: "right" },
            [
              _c(
                "tool-head",
                {
                  attrs: { type: "defeeredInterest" },
                  on: { clickItem: _vm.clickItem },
                },
                [
                  _c("font-size-comp", {
                    ref: "fontSizeComp",
                    on: { setFontSize: _vm.setFontSize },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "tools-deferredInterest",
          staticStyle: { "font-size": "14px !important" },
          attrs: { id: "tools" },
        },
        [
          _c(
            "div",
            { staticClass: "box", staticStyle: { padding: "0px 20px" } },
            [
              _c("div", { staticClass: "box-left" }, [
                _vm._v(" 金额"),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.money,
                        expression: "money",
                      },
                    ],
                    staticStyle: { "margin-left": "5px" },
                  },
                  [_vm._v(_vm._s(_vm.yuanToWan(_vm.money)))]
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "box-right",
                  staticStyle: { display: "flex", "align-items": "center" },
                },
                [
                  _c("van-field", {
                    class:
                      _vm.keyboard === "money" && _vm.show
                        ? "van-number-keyboard-input-select"
                        : "",
                    staticStyle: { flex: "1" },
                    attrs: {
                      "input-align": "right",
                      placeholder: "请输入金额",
                      type: "number",
                      readonly: "",
                      clickable: "",
                    },
                    on: {
                      touchstart: function ($event) {
                        $event.stopPropagation()
                        _vm.show = true
                        _vm.keyboard = "money"
                      },
                    },
                    model: {
                      value: _vm.money,
                      callback: function ($$v) {
                        _vm.money = $$v
                      },
                      expression: "money",
                    },
                  }),
                  _c("van-icon", {
                    attrs: { name: "close" },
                    on: {
                      click: function ($event) {
                        _vm.money = ""
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "box", staticStyle: { padding: "0px 20px" } },
            [
              _c("div", { staticClass: "box-left" }, [_vm._v("日期")]),
              _c(
                "div",
                { staticClass: "box-right", staticStyle: { flex: "1" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "box-right-time",
                      on: {
                        click: function ($event) {
                          _vm.startTimeShow = true
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.startTime,
                              expression: "startTime",
                            },
                          ],
                        },
                        [_vm._v(_vm._s(_vm.startTime))]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.startTime,
                              expression: "!startTime",
                            },
                          ],
                          staticStyle: { color: "#ccc" },
                        },
                        [_vm._v("选择开始时间")]
                      ),
                    ]
                  ),
                  _c("div", [_vm._v("至")]),
                  _c(
                    "div",
                    {
                      staticClass: "box-right-time",
                      on: {
                        click: function ($event) {
                          _vm.endTimeShow = true
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.endTime,
                              expression: "endTime",
                            },
                          ],
                        },
                        [_vm._v(_vm._s(_vm.endTime))]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.endTime,
                              expression: "!endTime",
                            },
                          ],
                          staticStyle: { color: "#ccc" },
                        },
                        [_vm._v("选择结束时间")]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticStyle: { width: "80px", "text-align": "right" } },
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.day > 0,
                              expression: "day > 0",
                            },
                          ],
                        },
                        [_vm._v(_vm._s(_vm.day) + "天")]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "box", staticStyle: { padding: "0px 20px" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    width: "45%",
                  },
                },
                [
                  _c("div", { staticClass: "box-left" }, [_vm._v("判决利率")]),
                  _c("div", { staticClass: "box-right" }, [
                    _c(
                      "div",
                      { staticClass: "input-box" },
                      [
                        _c("van-field", {
                          class:
                            _vm.keyboard === "judgmentInterest" && _vm.show
                              ? "van-number-keyboard-input-select"
                              : "",
                          attrs: {
                            "input-align": "right",
                            placeholder: "请输入",
                            type: "number",
                            readonly: "",
                            clickable: "",
                          },
                          on: {
                            touchstart: function ($event) {
                              $event.stopPropagation()
                              _vm.show = true
                              _vm.keyboard = "judgmentInterest"
                            },
                          },
                          model: {
                            value: _vm.judgmentInterest,
                            callback: function ($$v) {
                              _vm.judgmentInterest = $$v
                            },
                            expression: "judgmentInterest",
                          },
                        }),
                        _c("van-icon", {
                          attrs: { name: "close" },
                          on: {
                            click: function ($event) {
                              _vm.judgmentInterest = ""
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c("span", [_vm._v("‱")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    width: "45%",
                  },
                },
                [
                  _c("div", { staticClass: "box-left" }, [_vm._v("延迟利率")]),
                  _c("div", { staticClass: "box-right" }, [
                    _c(
                      "div",
                      { staticClass: "input-box" },
                      [
                        _c("van-field", {
                          class:
                            _vm.keyboard === "deferredInterest" && _vm.show
                              ? "van-number-keyboard-input-select"
                              : "",
                          attrs: {
                            "input-align": "right",
                            placeholder: "请输入",
                            type: "number",
                            readonly: "",
                            clickable: "",
                          },
                          on: {
                            touchstart: function ($event) {
                              $event.stopPropagation()
                              _vm.show = true
                              _vm.keyboard = "deferredInterest"
                            },
                          },
                          model: {
                            value: _vm.deferredInterest,
                            callback: function ($$v) {
                              _vm.deferredInterest = $$v
                            },
                            expression: "deferredInterest",
                          },
                        }),
                        _c("van-icon", {
                          attrs: { name: "close" },
                          on: {
                            click: function ($event) {
                              _vm.deferredInterest = ""
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c("span", [_vm._v("‱")]),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.result,
                  expression: "result",
                },
              ],
              staticClass: "interest-result",
            },
            [
              _c("div", [_vm._v("利息：" + _vm._s(_vm.interest))]),
              _c("div", [_vm._v("本金利息：" + _vm._s(_vm.result))]),
              _c("i", {
                staticClass: "iconfont icon-fuzhi",
                staticStyle: {
                  color: "#000000",
                  "margin-left": "20px",
                  cursor: "pointer",
                },
                on: { click: _vm.copy },
              }),
            ]
          ),
          _c("div", { staticClass: "interest-result" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.result,
                    expression: "result",
                  },
                ],
                on: {
                  click: function ($event) {
                    _vm.isShowInfo = !_vm.isShowInfo
                  },
                },
              },
              [_vm._v("详情")]
            ),
            _c("span", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.result,
                  expression: "!result",
                },
              ],
            }),
            _c("span", [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.result,
                      expression: "result",
                    },
                  ],
                  staticStyle: { "margin-right": "10px" },
                  on: { click: _vm.copyAll },
                },
                [_vm._v("复制")]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.result && !_vm.iphone,
                      expression: "result && !iphone",
                    },
                  ],
                  staticStyle: { "margin-right": "10px" },
                  on: { click: _vm.getWORD },
                },
                [_vm._v("导出word")]
              ),
              _c(
                "span",
                {
                  staticStyle: { "margin-right": "10px" },
                  on: { click: _vm.share },
                },
                [_vm._v("分享")]
              ),
              _c("span", { on: { click: _vm.feedback } }, [_vm._v("反馈")]),
            ]),
          ]),
          _c("div", { staticClass: "interest-line" }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShowInfo,
                  expression: "isShowInfo",
                },
              ],
              staticStyle: { padding: "0 20px" },
            },
            [
              _c("p", { staticStyle: { color: "#ccc" } }, [
                _vm._v(" 利息=本金×判决利率*天数+本金*延迟利率*延迟天数"),
                _c("span", { staticStyle: { "margin-left": "10px" } }, [
                  _vm._v(_vm._s(_vm.resultObj.day) + "天"),
                ]),
              ]),
              _c("p", [_vm._v(_vm._s(_vm.text1))]),
              _c("p", { staticStyle: { color: "#ccc" } }, [
                _vm._v("本息=本金+利息"),
              ]),
              _c("p", [_vm._v(_vm._s(_vm.text2))]),
            ]
          ),
        ]
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "top" },
          model: {
            value: _vm.startTimeShow,
            callback: function ($$v) {
              _vm.startTimeShow = $$v
            },
            expression: "startTimeShow",
          },
        },
        [
          _c("van-datetime-picker", {
            attrs: {
              type: "date",
              title: "选择开始时间",
              "min-date": _vm.minDate,
              "max-date": _vm.maxDate,
            },
            on: {
              confirm: _vm.startTimeConfirm,
              cancel: function ($event) {
                _vm.startTimeShow = false
              },
            },
            model: {
              value: _vm.startTimeDate,
              callback: function ($$v) {
                _vm.startTimeDate = $$v
              },
              expression: "startTimeDate",
            },
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "top" },
          model: {
            value: _vm.endTimeShow,
            callback: function ($$v) {
              _vm.endTimeShow = $$v
            },
            expression: "endTimeShow",
          },
        },
        [
          _c("van-datetime-picker", {
            attrs: {
              type: "date",
              title: "选择结束时间",
              "min-date": _vm.minDate,
              "max-date": _vm.maxDate,
            },
            on: {
              confirm: _vm.endTimeConfirm,
              cancel: function ($event) {
                _vm.endTimeShow = false
              },
            },
            model: {
              value: _vm.endTimeDate,
              callback: function ($$v) {
                _vm.endTimeDate = $$v
              },
              expression: "endTimeDate",
            },
          }),
        ],
        1
      ),
      _c("van-number-keyboard", {
        attrs: {
          show: _vm.show,
          theme: "custom",
          "extra-key": ".",
          "close-button-text": "完成",
        },
        on: {
          blur: function ($event) {
            _vm.show = false
          },
          input: _vm.onInput,
          delete: _vm.onDelete,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }